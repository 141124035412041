var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"team"},[_c('Header'),_c('div',[_c('div',[_c('wd-img',{attrs:{"src":_vm.imgUrl + 'team/banner.png',"fit":"cover"}})],1),_c('div',{staticClass:"p20 bgwhite f22 flex_start flex_items"},[_c('div',{staticClass:"f20 ptb5 mr40",staticStyle:{"width":"66px"}},[_vm._v("职位筛选")]),_c('div',{staticClass:"flex_start flex_items",staticStyle:{"white-space":"nowrap","overflow-y":"auto"}},_vm._l((_vm.titles),function(item,index){return _c('div',{key:index,class:[
            'ptb5',
            'plr20',
            'mr10',
            'ss',
            'f20',
            index == _vm.choseone ? 'chose1' : 'no1'
          ],on:{"click":function($event){return _vm.chose1(index, item)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0)])]),(_vm.list.length != 0)?_c('div',{staticClass:"p20 bgwhite"},[_c('div',{staticClass:"flex_warp flex_items",staticStyle:{"justify-content":"space-between"}},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:['flex_column', 'pos', index % 2 === 1 ? '' : ''],staticStyle:{"width":"48%"}},[_c('wd-img',{staticClass:"abs",staticStyle:{"width":"55px","height":"20px","left":"0","top":"10px","z-index":"1"},attrs:{"src":_vm.imgUrl + 'team/tuijian.png',"fit":"contain"}}),_c('wd-img',{attrs:{"src":_vm.tuhose + item.url,"fit":"cover"}}),_c('div',{staticClass:"flex_column mt10"},[_c('div',{staticClass:"flex_between"},[_c('span',{staticClass:"bold f26"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"f20"},[_vm._v(_vm._s(item.work_experience)+"年经验")])]),_c('div',{staticClass:"flex_items mt10 flex_start"},[_c('span',{staticClass:"f14 mr10"},[_vm._v("专业等级:")]),_c('wd-rate',{attrs:{"readonly":"","size":"12px"},model:{value:(item.grade),callback:function ($$v) {_vm.$set(item, "grade", $$v)},expression:"item.grade"}})],1),_c('div',{staticClass:"flex_items mt10 flex_start pb30"},[_c('span',{staticClass:"f14 mr10"},[_vm._v("业主评分:")]),_c('wd-rate',{staticStyle:{"margin-right":"0px"},attrs:{"readonly":"","size":"12px"},model:{value:(item.owner_score),callback:function ($$v) {_vm.$set(item, "owner_score", $$v)},expression:"item.owner_score"}})],1)])],1)}),0)]):_c('div',{staticClass:"noDetail"},[_vm._v("-- 暂无数据 --")]),(_vm.list.length != 0)?_c('wd-pagination',{staticClass:"p20 pb30",attrs:{"page-size":"20","total":_vm.total},on:{"change":_vm.getData},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),_c('div',{staticClass:"bgwhite pt40"},[_c('Footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }