<template>
  <div class="wall">
    <Header />
    <div class="">
      <div class="flex_column flex_items">
        <wd-img :src="imgUrl + 'qiang/banner.png'" fit="cover" />
        <wd-img :src="imgUrl + 'qiang/qiangmianwenti.png'" fit="cover" />
        <wd-img :src="imgUrl + 'qiang/ninzhidaoma.png'" fit="cover" />
        <wd-img :src="imgUrl + 'qiang/jiejue-.png'" fit="cover" />
        <wd-img :src="imgUrl + 'qiang/cailiao.png'" fit="cover" />
      </div>
      <!-- 预约 -->
      <div class="p20">
        <div
          class="plr20 flex_column flex_items bgwhite pb30"
          style="border-radius: 20px"
        >
          <div class="flex_items flex_column mt20">
            <p class="bold f28">房屋维修改造翻新具体需要多少钱</p>
            <p class="f20 mt10" style="color: #8d8d8d">
              自营门店 · 专业工人 · 店长上门检测 · 15分钟极速响应
            </p>
          </div>
          <div class="flex_column mt20" style="width: 100%">
            <div class="flex_start pos" style="width: 100%">
              <input
                type="text"
                placeholder="姓名"
                v-model="info.user"
                class="f24 p5"
                @click="show = true"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 电话 -->
            <div class="flex_start pos mt10" style="width: 100%">
              <input
                type="text"
                placeholder="联系电话"
                v-model="info.phone"
                class="f24 p5"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 房屋问题 -->
            <div class="flex_start pos mt10" style="width: 100%">
              <div
                type="text"
                class="f24 p5 f-left"
                @click="pickerVisible = true"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                  line-height: 35px;
                  color: #8d8d8d;
                "
              >
                {{ result }}
              </div>
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <wd-button
              @click="onsubmit"
              block
              size="large"
              class="mt30"
              style="height: 45px; background: #007B46; border-radius: 5px"
              >立即预约</wd-button
            >
          </div>
        </div>
      </div>
      <div class="mt20 bgwhite">
        <wd-img :src="imgUrl + 'qiang/fuwuliucheng.png'" fit="cover" />
      </div>
      <div class="flex_column flex_items">
        <wd-img :src="imgUrl + 'qiang/toumingshigong.png'" fit="cover" />
        <wd-img :src="imgUrl + 'qiang/xiaochengxu.png'" fit="cover" />
      </div>
      <div class="flex_column flex_items">
        <div class="flex_items flex_column mt40 pb20">
          <p class="bold f32">真实施工案列，工地就在您身边</p>
          <p class="f20 mt10">100000+ 房屋诊断分析数据，97.6%好评率</p>
        </div>
        <wd-img :src="imgUrl + 'qiang/qiangmianhuanse.png'" fit="cover" />
        <div class="flex_items flex_column mt20 pb20">
          <p class="bold f32">墙面换色</p>
        </div>
        <wd-img :src="imgUrl + 'qiang/yishuqiang.png'" fit="cover" />
        <div class="flex_items flex_column mt20 pb20">
          <p class="bold f32">多彩艺术墙</p>
        </div>
        <wd-img :src="imgUrl + 'qiang/qiangmianwusun.png'" fit="cover" />
        <div class="flex_items flex_column mt20 pb20">
          <p class="bold f32">墙面污损</p>
        </div>
      </div>
      <div class="plr20 bgwhite pb40">
        <wd-button
          type="info"
          block
          class="mt30 mu"
          @click="morecase"
          style="
            height: 40px;
            font-size: 16px !important;
            color: #666666;
            background: white;
          "
          >查看更多</wd-button
        >
      </div>
      <div>
        <wd-img :src="imgUrl + 'qiang/koubei.png'" fit="cover" />
      </div>
    </div>
    <div v-if="pickData.length != 0">
      <VuePicker
        :data="pickData"
        :showToolbar="true"
        @confirm="confirm"
        :visible.sync="pickerVisible"
      />
    </div>
    <div class="bgwhite pt40">
      <Footer />
    </div>
  </div>
</template>
<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
import VuePicker from "vue-pickers";
var tdata = [];
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      info: {
        user: "",
        phone: ""
      },
      // picker
      pickerVisible: false,
      pickData: [tdata],
      result: "选择房屋问题"
    };
  },
  created() {
    this.$store.commit("setpage", "产品中心");
    this.getlei();
  },
  methods: {
    getlei() {
      this.util.get(this.HOST + "/api/service_category/", {}).then(res => {
        console.log(res);
        if (res.errcode == 200) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].value = res.data[i].category_id;
            res.data[i].label = res.data[i].name;
            if (tdata.length <= 3) {
              tdata.push({
                value: res.data[i].category_id,
                label: res.data[i].name
              });
            }
          }
        }
      });
    },
    morecase() {
      this.$router.push({
        path: "/yicase",
        query: {}
      });
    },
    onsubmit() {
      if (this.info.phone == "") {
        this.$toast.warning("请填写电话号码");
        return false;
      }
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 1,
          name: this.info.user,
          mobile: this.info.phone,
          category_id: "",
          describe: "",
          area: ""
        })
        .then(res => {
          console.log(res);
          if (res.errcode == 200) {
            this.$toast.success("提交成功");
          } else {
            this.$toast.error(res.msg);
          }
        });
    },
    confirm(res) {
      console.log(res);
      this.result = res[0].label;
      this.info.wen = res[0].value;
    }
  },
  components: {
    Header: header,
    Footer: footer,
    VuePicker
  }
};
</script>
<style>
.mu .wd-button__txt {
  font-size: 26px !important;
}
</style>
