<template>
  <div class="jubu">
    <Header></Header>
    <div class="warp" style="overflow: hidden">
      <!-- <wd-img :src="imgUrl + 'jubu/banner.png'" fit="cover" /> -->
      <wd-swipe>
        <wd-swipe-item v-for="item in bbs" :key="item">
          <wd-img :src="item" fit="cover" />
        </wd-swipe-item>
      </wd-swipe>
      <!-- 麻烦不断 -->
      <div class="bgwhite pt20">
        <wd-img :src="imgUrl + 'jubu/mafan.png'" fit="cover" />
      </div>
      <!-- 预约 -->
      <div class="p20">
        <div
          class="plr20 flex_column flex_items bgwhite pb30"
          style="border-radius: 20px"
        >
          <div class="flex_items flex_column mt20">
            <p class="bold f28">房屋维修改造翻新具体需要多少钱</p>
            <p class="f20 mt10" style="color: #8d8d8d">
              自营门店 · 专业工人 · 店长上门检测 · 15分钟极速响应
            </p>
          </div>
          <div class="flex_column mt20" style="width: 100%">
            <div class="flex_start pos" style="width: 100%">
              <input
                type="text"
                placeholder="姓名"
                v-model="info.user"
                class="f24 p5"
                @click="show = true"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 电话 -->
            <div class="flex_start pos mt10" style="width: 100%">
              <input
                type="text"
                placeholder="联系电话"
                v-model="info.phone"
                class="f24 p5"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 房屋问题 -->
            <div class="flex_start pos mt10" style="width: 100%">
              <div
                type="text"
                class="f24 p5 f-left"
                @click="pickerVisible = true"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                  line-height: 35px;
                  color: #8d8d8d;
                "
              >
                {{ result }}
              </div>
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <wd-button
              @click="onsubmit"
              block
              size="large"
              class="mt30"
              style="height: 45px; background: #007B46; border-radius: 5px"
              >立即预约</wd-button
            >
          </div>
        </div>
      </div>
      <!-- 旧房改造 -->
      <div class="bgwhite">
        <wd-img :src="imgUrl + 'jubu/tongdian.png'" fit="cover" />
      </div>
      <!-- 任何 -->
      <div class="bgwhite">
        <wd-img :src="imgUrl + 'jubu/kongjiangaizao.png'" fit="cover" />
      </div>
      <!-- 主要改造空间 -->
      <div class="bgwhite pt20">
        <div class="flex_items flex_column mt20">
          <p class="bold f32">主要改造空间</p>
          <p class="f20 mt10" style="color: #8d8d8d">
            更多改造，更多美好~等你探索
          </p>
        </div>
        <div class="flex_around flex_items mt30">
          <div
            :class="ta == index ? 'acc' : ''"
            v-for="(item, index) in gais"
            :key="index"
            @click="tiao(index)"
          >
            <span class="f18">{{ item.t }}</span>
          </div>
        </div>
        <wd-swipe class="mt30" @change="handleItemChange" ref="carousel">
          <wd-swipe-item v-for="(item, index) in gais" :key="index">
            <div class="pos">
              <wd-img :src="item.url" fit="cover" />
              <div
                class="abs flex_center flex_items f24"
                style="
                  bottom: 4px;
                  left: 0;
                  width: 100%;
                  height: 40px;
                  color: white;
                  background: rgba(10, 9, 8, 0.4);
                "
              >
                <div class="flex_around flex_items">
                  <div class="ling mr10"></div>
                  <p class="mr10">{{ item.t2 }}</p>
                  <div class="ling"></div>
                </div>
              </div>
            </div>
          </wd-swipe-item>
        </wd-swipe>
      </div>
      <!--  -->
      <div class="bgwhite">
        <wd-img :src="imgUrl + 'jubu/shouhuanyinganlie123.png'" fit="fill" />
      </div>
      <!-- 好评 -->
      <div class="bgwhite">
        <wd-img :src="imgUrl + 'jubu/kehuhaoping.png'" fit="fill" />
      </div>
      <!-- 预约 -->
      <div class="p20">
        <div
          class="plr20 flex_column flex_items bgwhite pb30"
          style="border-radius: 20px"
        >
          <div class="flex_items flex_column mt20">
            <p class="bold f32">房屋维修改造翻新具体需要多少钱</p>
            <p class="f20 mt10" style="color: #8d8d8d">
              自营门店 · 专业工人 · 店长上门检测 · 15分钟极速响应
            </p>
          </div>
          <div class="flex_column mt20" style="width: 100%">
            <div class="flex_start pos" style="width: 100%">
              <input
                type="text"
                placeholder="姓名"
                v-model="info.user"
                class="f24 p5"
                @click="show = true"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 电话 -->
            <div class="flex_start pos mt10" style="width: 100%">
              <input
                type="text"
                placeholder="联系电话"
                v-model="info.phone"
                class="f24 p5"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 房屋问题 -->
            <div class="flex_start pos mt10" style="width: 100%">
              <div
                type="text"
                class="f24 p5 f-left"
                @click="pickerVisible = true"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                  line-height: 35px;
                  color: #8d8d8d;
                "
              >
                {{ result }}
              </div>
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <wd-button
              @click="onsubmit"
              block
              size="large"
              class="mt30"
              style="height: 45px; background: #007B46; border-radius: 5px"
              >立即预约</wd-button
            >
          </div>
        </div>
      </div>
      <!-- 透明 -->
      <div class="bgwhite flex_column flex_items pb30">
        <wd-img :src="imgUrl + 'jubu/toumingshigong.png'" fit="cover" />
        <wd-img :src="imgUrl + 'jubu/xiaochengxu.png'" fit="cover" />
      </div>
    </div>
    <div v-if="pickData.length != 0">
      <VuePicker
        :data="pickData"
        :showToolbar="true"
        @confirm="confirm"
        :visible.sync="pickerVisible"
      />
    </div>
    <div class="bgwhite pt20">
      <Footer />
    </div>
  </div>
</template>

<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
import VuePicker from "vue-pickers";
let tdata = [];
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      wens: [],
      info: {
        user: "",
        phone: ""
      },
      gais: [
        {
          t: "厨房改造",
          t2: "极速厨房翻新，施工仅需一个周末",
          url: this.util.imgUrl + "jubu/a1.jpg"
        },
        {
          t: "卫生间改造",
          t2: "多点联防，全方位防水防臭",
          url: this.util.imgUrl + "jubu/weishengjian.jpg"
        },
        {
          t: "背景墙改造",
          t2: "个性化打造，尽显生活品质",
          url: this.util.imgUrl + "jubu/beijingqiang.jpg"
        },
        {
          t: "阳台改造",
          t2: "阳台改得好，多出一间房",
          url: this.util.imgUrl + "jubu/yangtai.jpg"
        }
      ],
      ta: 0,
      bbs: [
        this.util.imgUrl + "jubu/banner.png",
        this.util.imgUrl + "jubu/yuo12.jpg"
      ],
      // picker
      pickerVisible: false,
      pickData: [tdata],
      result: "选择房屋问题"
    };
  },
  created() {
    this.$store.commit("setpage", "产品中心");
    this.getlei();
  },
  methods: {
    getlei() {
      this.util.get(this.HOST + "/api/service_category/", {}).then(res => {
        console.log(11234);
        if (res.errcode == 200) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].value = res.data[i].category_id;
            res.data[i].label = res.data[i].name;
            if (tdata.length <= 3) {
              tdata.push({
                value: res.data[i].category_id,
                label: res.data[i].name
              });
            }
          }
        }
      });
    },
    confirm(res) {
      console.log(res);
      this.result = res[0].label;
      this.info.wen = res[0].value;
    },
    onsubmit() {
      if (this.info.phone == "") {
        this.$toast.warning("请填写电话号码");
        return false;
      }
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 1,
          name: this.info.user,
          mobile: this.info.phone,
          category_id: "",
          describe: "",
          area: ""
        })
        .then(res => {
          console.log(res);
          if (res.errcode == 200) {
            this.$toast.success("提交成功");
          } else {
            this.$toast.error(res.msg);
          }
        });
    },
    handleItemChange(val) {
      this.ta = val;
    },
    tiao(idx) {
      this.ta = idx;
      this.$refs.carousel.swipeTo(idx);
    }
  },
  components: {
    Header: header,
    Footer: footer,
    VuePicker
  }
};
</script>
<style>
.jubu .acc {
  background: #04713d;
  color: white;
  padding: 5px 13px;
  border-radius: 15px;
}
.ling {
  width: 15px;
  height: 15px;
  transform: rotateZ(45deg) skew(15deg, 15deg);
  background: #d7ba7e;
}
</style>
