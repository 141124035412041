<template>
  <div class="rezhao">
    <Header />
    <div class="">
      <div class="plr20 ptb30 flex_column">
        <div class="flex_between flex_items">
          <span class="bold f32">{{ lists.title }}</span>
          <span class="bold f30" style="color: red">{{ lists.salary }}</span>
        </div>
        <div class="flex_start flex_items mt30 f24" style="color: #8d8d8d">
          <span class="mr30 f-left" style="width: 170px">{{
            lists.place
          }}</span>
          <span class="mr30">招{{ lists.count }}人</span>
          <span>{{ lists.education }}</span>
        </div>
      </div>
      <div class="bgwhite p20 flex_column f-left" style="padding-bottom:50px">
        <p class="bold f32 mt30">岗位职责</p>
        <p v-html="lists.duty_content" class="f24 mt20 xiap"></p>
        <p class="bold f32 mt30">任职资格</p>
        <p v-html="lists.demand_content" class="f24 mt20 xiap"></p>
        <p class="bold f32 mt30">工作地点</p>
        <p class="f24 mt20 xiap" style="color:#8d8d8d">{{ lists.place }}</p>
        <p class="bold f32 mt30">联系方式</p>
        <p class="f24 mt20 xiap">简历投递邮箱：{{ lists.email }}</p>
        <p class="f24 mt20 xiap">联系电话：{{ lists.phone }}</p>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
export default {
  data() {
    return {
      lists: {},
    };
  },
  mounted() {
    console.log(JSON.parse(localStorage.getItem("zhi")));
    this.lists = JSON.parse(localStorage.getItem("zhi"));
  },
  methods: {},
  components: {
    Header: header,
    Footer: footer,
    // VuePicker,
  },
};
</script>
<style>
.page {
  background: #f5f5f5;
}
.xiap p {
  margin-top: 20px;
  color: #8d8d8d;
}
</style>