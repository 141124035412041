<template>
  <div class="newsdetail bgwhite">
    <Header />
    <div>
      <wd-img :src="imgUrl + 'news/banner.png'" fit="cover" />
      <div class="flex_column flex_items ptb20">
        <p class="bold f30 plr20 pb20 bbe9s">{{ model.title }}</p>
        <div
          class="flex_between flex_items ptb10 f22"
          style="width: 94%; color: #b3b3b3"
        >
          <span style="font-weight:200">发布时间:&nbsp;{{ model.created_at }}</span>
          <span style="font-weight:200">发布者&nbsp;{{ model.author }}</span>
        </div>
      </div>
      <div
        v-if="model.textContent"
        v-html="model.textContent.content"
        class="p20 f24 f-left wenben"
      ></div>
    </div>
    <div class="bgwhite pt20">
      <Footer />
    </div>
  </div>
</template>
<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
import VuePicker from "vue-pickers";
let tdata = [];
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      tuhose: this.util.tuhose,
      lists: [],
      model: {},
    };
  },
  created() {
    this.$store.commit("setpage", "新闻资讯");
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    getData() {
      this.util
        .get(this.HOST + "/api/article_Info", {
          id: this.id,
        })
        .then((res) => {
          console.log(res);
          if (res.errcode == 200) {
            this.model = res.data.model;
            this.lists = res.data.new_articles.data;
          }
        });
    },
  },
  components: {
    Header: header,
    Footer: footer,
    // VuePicker,
  },
};
</script>
<style>
.wenben img {
  width: 100%;
  margin: 10px 0;
}
</style>