<template>
  <div class="news">
    <Header />
    <div>
      <wd-img :src="imgUrl + 'news/banner.png'" fit="cover" />
      <div class="flex_around flex_items bgwhite ptb20">
        <div
          v-for="(item, index) in titles"
          :key="index"
          :class="['f24', 'plr15', 'ptb5', index == cur ? 'xuanle' : '']"
          @click="chxuanze(item.category_id, index)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- list -->
      <div v-if="lists.length != 0" class="flex_column">
        <div
          v-for="(item, index) in lists"
          :key="index"
          class="p20 bgwhite flex_start"
          @click="newdetail(item.id)"
        >
          <wd-img
            :src="tuhose + item.url"
            fit="cover"
            class="mr20"
            style="width: 130px; height: 120px"
          />
          <div class="flex_column pos" style="width: 200px">
            <p class="bold f24 f-left sheng">{{ item.title }}</p>
            <p class="f20 mt10 f-left sheng" style="color: #8d8d8d">
              {{ item.subtitle }}
            </p>
            <div
              class="abs flex_between flex_items pt10 bte9s f12"
              style="bottom: 0; left: 0; color: #8d8d8d; width: 100%"
            >
              <span>{{ item.updated_at }}</span>
              <span class="bold">{{ item.browse }}人浏览</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="noDetail">-- 暂无新闻资讯 --</div>
      <wd-pagination
        @change="getData"
        v-if="lists.length != 0"
        page-size="10"
        v-model="page"
        :total="total"
        class="p20 pb30"
      >
      </wd-pagination>
    </div>
    <div class="bgwhite pt20">
      <Footer />
    </div>
  </div>
</template>

<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
import VuePicker from "vue-pickers";
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      tuhose: this.util.tuhose,
      titles: [],
      lists: [],
      total: 0,
      cur: 0,
      id: 13,
      page: 1
    };
  },
  created() {
    this.getData();
  },
  methods: {
    chxuanze(id, idx) {
      this.cur = idx;
      this.id = id;
      this.page = 1;
      this.getData();
    },
    getData() {
      this.util
        .get(this.HOST + "/api/article/", {
          category_id: this.id,
          page: this.page
        })
        .then(res => {
          console.log(res);
          if (res.errcode == 200) {
            this.titles = res.data.category_arr;
            this.lists = res.data.articles.data;
            this.total = res.data.articles.total;
          }
        });
    },
    newdetail(idx) {
      this.$router.push({
        path: "/newsdetail",
        query: {
          id: idx
        }
      });
    }
  },
  components: {
    Header: header,
    Footer: footer
    // VuePicker,
  }
};
</script>
<style>
.news .xuanle {
  color: white;
  background: #007b46;
  border-radius: 20px;
}
.news .sheng {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.news .wd-pager__size,
.news .wd-pager__current {
  font-size: 22px !important;
}
.news .wd-button.is-small {
  font-size: 22px;
  height: 35px;
  line-height: 35px;
}
</style>
