<template>
  <div class="fang">
    <Header />
    <div class="">
      <div>
        <wd-img :src="imgUrl + 'fang/banner.png'" fit="cover" />
      </div>
      <div class="flex_column flex_items">
        <wd-img :src="imgUrl + 'fang/wenti.png'" fit="cover" />
        <wd-img :src="imgUrl + 'fang/yiqi.png'" fit="cover" />
        <wd-img :src="imgUrl + 'fang/shigongxianchang.png'" fit="cover" />
      </div>
      <!-- 预约 -->
      <div class="p20">
        <div
          class="plr20 flex_column flex_items bgwhite pb30"
          style="border-radius: 20px"
        >
          <div class="flex_items flex_column mt20">
            <p class="bold f28">房屋维修改造翻新具体需要多少钱</p>
            <p class="f20 mt10" style="color: #8d8d8d">
              自营门店 · 专业工人 · 店长上门检测 · 15分钟极速响应
            </p>
          </div>
          <div class="flex_column mt20" style="width: 100%">
            <div class="flex_start pos" style="width: 100%">
              <input
                type="text"
                placeholder="姓名"
                v-model="info.user"
                class="f24 p5"
                @click="show = true"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 电话 -->
            <div class="flex_start pos mt10" style="width: 100%">
              <input
                type="text"
                placeholder="联系电话"
                v-model="info.phone"
                class="f24 p5"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 房屋问题 -->
            <div class="flex_start pos mt10" style="width: 100%">
              <div
                type="text"
                class="f24 p5 f-left"
                @click="pickerVisible = true"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                  line-height: 35px;
                  color: #8d8d8d;
                "
              >
                {{ result }}
              </div>
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <wd-button
              @click="onsubmit"
              block
              size="large"
              class="mt30"
              style="height: 45px; background: #007B46; border-radius: 5px"
              >立即预约</wd-button
            >
          </div>
        </div>
      </div>
      <!-- 黑科技 -->
      <div class="pb20 t6">
        <div class="flex_center flex_items">
          <div class="flex_items flex_column mt40">
            <p class="bold f32" style="color: white">益鸟防水黑科技,专利技术</p>
            <p class="f20 mt10" style="color: white">施工仅需8小时</p>
          </div>
        </div>
        <div class="flex_center flex_items mt20">
          <div class="" style="width: 100%">
            <video-player
              ref="videoPlayer"
              class="video-player vjs-custom-skin mr15"
              :playsinline="true"
              :options="playerOptions"
            />
          </div>
        </div>
      </div>
      <!-- 工艺 -->
      <div class="flex_column flex_items">
        <wd-img :src="imgUrl + 'fang/gongyi.png'" fit="cover" />
        <wd-img :src="imgUrl + 'fang/gongyi1.png'" fit="cover" />
      </div>
      <!-- 案例展示 -->
      <div class="bgwhite plr20 pt20 pb40">
        <div class="flex_items flex_column mt20">
          <p class="bold f32">工地就在您身边</p>
        </div>
        <div class="flex_warp flex_items mt20">
          <div
            class="flex_column mr20 mt20 mm"
            v-for="(item, index) in shigongs"
            :key="index"
            style="width: 48%"
          >
            <wd-img :src="item.url" fit="cover" />
            <p class="bold f20 mt15 f-center">{{ item.t1 }}·{{ item.t2 }}</p>
          </div>
        </div>
        <wd-button
          type="info"
          block
          class="mt30 mu"
          @click="morecase"
          style="
            height: 40px;
            font-size: 16px !important;
            color: #666666;
            background: white;
          "
          >查看更多</wd-button
        >
      </div>
      <wd-img :src="imgUrl + 'fang/koubei.png'" fit="cover" />
    </div>
    <div v-if="pickData.length != 0">
      <VuePicker
        :data="pickData"
        :showToolbar="true"
        @confirm="confirm"
        :visible.sync="pickerVisible"
      />
    </div>
    <div class="bgwhite pt40">
      <Footer />
    </div>
  </div>
</template>
<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
import VuePicker from "vue-pickers";
let tdata = [];
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      wens: [],
      info: {
        user: "",
        phone: ""
      },
      // picker
      pickerVisible: false,
      pickData: [tdata],
      result: "选择房屋问题",
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "6:4", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "https://imgaes.yiniaoweb.com/gw/video/1.mp4" // url地址
          }
        ],
        hls: true,
        poster: this.util.imgUrl + "home/shipin.png", // 你的封面地址
        width: "100%", // 播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //时间分割线
          durationDisplay: true, //总时间
          remainingTimeDisplay: false, //剩余播放时间
          progressControl: true, // 进度条
          fullscreenToggle: true // 全屏按钮
        }
      },
      shigongs: [
        {
          url: this.util.imgUrl + "fang/anlie1.png",
          t1: "保利玫瑰花语",
          t2: "卫生间防水"
        },
        {
          url: this.util.imgUrl + "fang/anlie2.png",
          t1: "电建洺悦府",
          t2: "阳台防水"
        },
        {
          url: this.util.imgUrl + "fang/anlie3.png",
          t1: "新华小区",
          t2: "屋顶防水"
        },
        {
          url: this.util.imgUrl + "fang/anlie4.png",
          t1: "汇融悉尼湾",
          t2: "雨棚防水"
        },
        {
          url: this.util.imgUrl + "fang/anlie5.png",
          t1: "柳岸银州",
          t2: "飘窗防水"
        },
        {
          url: this.util.imgUrl + "fang/anlie6.png",
          t1: "山水时光",
          t2: "墙面防水"
        }
      ]
    };
  },
  created() {
    this.$store.commit("setpage", "产品中心");
    this.getlei();
  },
  methods: {
    getlei() {
      this.util.get(this.HOST + "/api/service_category/", {}).then(res => {
        console.log(res);
        if (res.errcode == 200) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].value = res.data[i].category_id;
            res.data[i].label = res.data[i].name;
            if (tdata.length <= 3) {
              tdata.push({
                value: res.data[i].category_id,
                label: res.data[i].name
              });
            }
          }
        }
      });
    },
    morecase() {
      this.$router.push({
        path: "/yicase",
        query: {}
      });
    },
    confirm(res) {
      console.log(res);
      this.result = res[0].label;
      this.info.wen = res[0].value;
    },
    onsubmit() {
      if (this.info.phone == "") {
        this.$toast.warning("请填写电话号码");
        return false;
      }
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 1,
          name: this.info.user,
          mobile: this.info.phone,
          category_id: "",
          describe: "",
          area: ""
        })
        .then(res => {
          console.log(res);
          if (res.errcode == 200) {
            this.$toast.success("提交成功");
          } else {
            this.$toast.error(res.msg);
          }
        });
    }
  },
  components: {
    Header: header,
    Footer: footer,
    VuePicker
  }
};
</script>

<style>
.fang .t6 {
  background: url("https://imgaes.yiniaoweb.com/gw/mobile/fang/beijing.png")
    no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;
  width: 100%;
}
.fang .mm:nth-child(2),
.fang .mm:nth-child(4),
.fang .mm:nth-child(6) {
  margin-right: 0 !important;
}
.mu .wd-button__txt {
  font-size: 26px !important;
}
.mu .wd-button__txt {
  font-size: 26px !important;
}
</style>
