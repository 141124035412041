<template>
  <div class="team">
    <Header />
    <div>
      <div>
        <wd-img :src="imgUrl + 'team/banner.png'" fit="cover" />
      </div>
      <div class="p20 bgwhite f22 flex_start flex_items">
        <div class="f20 ptb5 mr40" style="width: 66px">职位筛选</div>
        <div
          class="flex_start flex_items"
          style="white-space: nowrap; overflow-y: auto"
        >
          <div
            v-for="(item, index) in titles"
            :key="index"
            :class="[
              'ptb5',
              'plr20',
              'mr10',
              'ss',
              'f20',
              index == choseone ? 'chose1' : 'no1'
            ]"
            @click="chose1(index, item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <!-- list -->
    <div class="p20 bgwhite" v-if="list.length != 0">
      <div
        class="flex_warp flex_items"
        style=" justify-content: space-between;"
      >
        <div
          v-for="(item, index) in list"
          :key="index"
          :class="['flex_column', 'pos', index % 2 === 1 ? '' : '']"
          style="width: 48%;"
        >
          <wd-img
            :src="imgUrl + 'team/tuijian.png'"
            fit="contain"
            class="abs"
            style="width: 55px; height: 20px; left: 0; top: 10px; z-index: 1"
          />
          <wd-img :src="tuhose + item.url" fit="cover" />
          <div class="flex_column mt10">
            <div class="flex_between">
              <span class="bold f26">{{ item.name }}</span>
              <span class="f20">{{ item.work_experience }}年经验</span>
            </div>
            <div class="flex_items mt10 flex_start">
              <span class="f14 mr10">专业等级:</span>
              <wd-rate v-model="item.grade" readonly size="12px"></wd-rate>
            </div>
            <div class="flex_items mt10 flex_start pb30">
              <span class="f14 mr10">业主评分:</span>
              <wd-rate
                v-model="item.owner_score"
                readonly
                size="12px"
                style="margin-right:0px"
              ></wd-rate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="noDetail">-- 暂无数据 --</div>
    <wd-pagination
      @change="getData"
      v-if="list.length != 0"
      page-size="20"
      v-model="page"
      :total="total"
      class="p20 pb30"
    >
    </wd-pagination>
    <div class="bgwhite pt40">
      <Footer />
    </div>
  </div>
</template>
<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      tuhose: this.util.tuhose,
      titles: [],
      list: [],
      choseone: 0,
      page: 1,
      total: 0
    };
  },
  created() {
    this.$store.commit("setpage", "");
    this.getData();
  },
  methods: {
    getData() {
      this.util
        .get(this.HOST + "/api/work/", {
          work_type: this.choseone,
          page: this.page
        })
        .then(res => {
          console.log(res);
          if (res.errcode == 200) {
            res.data.work.unshift("不限");
            this.titles = res.data.work;
            this.list = res.data.workers.data;
            this.total = res.data.workers.total;
          }
        });
    },
    chose1(idx, item) {
      this.choseone = idx;
      this.page = 1;
      this.getData();
    }
  },
  components: {
    Header: header,
    Footer: footer
    // VuePicker,
  }
};
</script>
<style>
.team .no1 {
  color: #000;
  background: none;
}
.team .chose1 {
  color: white;
  background: #007b46;
  border-radius: 18px;
}
.team .wd-pager__size,
.team .wd-pager__current {
  font-size: 22px !important;
}
.team .wd-button.is-small {
  font-size: 22px;
  height: 35px;
  line-height: 35px;
}
.wd-rate__item {
  margin-right: -4px !important;
}
</style>
