<template>
  <div class="casedetail">
    <Header />
    <div class="">
      <div class="ma" v-if="photos.length != 0">
        <wd-swipe @change="handleItemChange" class="pos">
          <wd-swipe-item v-for="(item, index) in photos" :key="index">
            <wd-img :src="item" fit="contain" />
          </wd-swipe-item>
          <template #indicators>
            <div
              class="custom-indicators abs"
              style="right: 10px; bottom: 15px; color: white"
            >
              {{ customIndex + 1 }}/{{ photos.length }}
            </div>
          </template>
        </wd-swipe>
      </div>
      <!-- 文 -->
      <div class="p20 bbe9s">
        <p class="bold f26">{{ model.title }}</p>
        <div class="flex_center flex_items">
          <div
            class="flex_between flex_items f18 mt10"
            style="width: 260px; color: rgb(143, 143, 143)"
          >
            <span>时间: {{ model.created_at }}</span>
            <span>浏览人数:{{ model.views }}</span>
          </div>
        </div>
      </div>
      <div class="flex_column p20 bbe9s">
        <div class="flex_between f22">
          <div class="flex_start flex_items as">
            <span class="mr10" style="">项目类型:</span>
            <span v-if="model.serviceCategory" style="" class="f-left ab">{{
              model.serviceCategory.name
            }}</span>
          </div>
          <div class="flex_start flex_items as">
            <span class="mr10" style="">小区名称:</span>
            <span style="" class="f-left ab">{{ model.fit_up_style }}</span>
          </div>
        </div>
        <div class="flex_between f22 mt20">
          <div class="flex_start flex_items as">
            <span class="mr10" style="">案例面积:</span>
            <span style="" class="f-left ab">{{ model.area }}</span>
          </div>
          <div class="flex_start flex_items as">
            <span class="mr10" style="">维修店铺:</span>
            <span style="" class="f-left ab">{{ wsStore.name }}</span>
          </div>
        </div>
        <div class="flex_between flex_items f22 mt10">
          <div class="flex_start flex_items mt20 f22">
            <span class=" f-left mr10">业主好评:</span>
            <wd-rate v-model="model.scoring" readonly></wd-rate>
          </div>
        </div>
        <div class="flex_between flex_items f22 mt10">
          <div class="flex_start flex_items mt20 f22">
            <span class=" f-left mr10">店铺地址:</span>
            <span class="f-left" style="color: #ffa405">{{
              wsStore.address
            }}</span>
          </div>
        </div>
      </div>
      <div class="p20 f22 f-left">业主说: {{ model.owner_evaluate }}</div>
      <!-- 来啦 -->
      <div class="flex_column flex_items plr20 pt20">
        <wd-img
          v-for="(item, index) in model.images1"
          :key="index"
          :src="tuhose + item.url"
          fit="cover"
        ></wd-img>
      </div>
      <div class="flex_column flex_items plr20 pt20">
        <wd-img
          v-for="(item, index) in model.images2"
          :key="index"
          :src="tuhose + item.url"
          fit="cover"
        ></wd-img>
      </div>
      <!-- 预约 -->
      <div class="p20">
        <div
          class="plr20 flex_column flex_items bgwhite pb30"
          style="border-radius: 20px"
        >
          <div class="flex_items flex_column mt20">
            <p class="bold f28">房屋维修改造翻新具体需要多少钱</p>
            <p class="f20 mt10" style="color: #8d8d8d">
              自营门店 · 专业工人 · 店长上门检测 · 15分钟极速响应
            </p>
          </div>
          <div class="flex_column mt20" style="width: 100%">
            <div class="flex_start pos" style="width: 100%">
              <input
                type="text"
                placeholder="姓名"
                v-model="info.user"
                class="f24 p5"
                @click="show = true"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 电话 -->
            <div class="flex_start pos mt10" style="width: 100%">
              <input
                type="text"
                placeholder="联系电话"
                v-model="info.phone"
                class="f24 p5"
                style="
                  height: 35px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  border-radius: 5px;
                  width: 100%;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <wd-button
              @click="onsubmit"
              block
              size="large"
              class="mt30"
              style="height: 45px; background: #d7ba7e; border-radius: 0"
              >立即预约</wd-button
            >
            <div
              class="ptb20 flex_center flex_items mt20"
              style="background: #007B46; color: white"
            >
              <div class="flex_start">
                <wd-img
                  class="mr10"
                  :src="imgUrl + 'case/dianhua.png'"
                  fit="cover"
                />
                <div class="flex_column">
                  <p class="f24 f-left mt10">客服咨询热线</p>
                  <p class="mt10 bold f34">4000-987-009</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bgwhite pt20">
      <Footer />
    </div>
  </div>
</template>

<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
import VuePicker from "vue-pickers";
let tdata = [];
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      tuhose: this.util.tuhose,
      photos: [],
      model: {},
      wsStore: {},
      processes: {},
      customIndex: 0,
      info: {
        user: "",
        phone: ""
      }
    };
  },
  created() {
    this.$store.commit("setpage", "益鸟案例");
    this.getData(this.$route.query.id);
  },
  methods: {
    getData(id) {
      this.util
        .get(this.HOST + "/api/exampleInfo", {
          id: id
        })
        .then(res => {
          console.log(res);
          if (res.errcode == 200) {
            this.model = res.data.model;
            this.wsStore = res.data.model.wsStore;
            this.processes = res.data.processes;
            for (let i = 0; i < res.data.model.images.length; i++) {
              this.photos.push(this.util.tuhose + res.data.model.images[i].url);
            }
          }
        });
    },
    handleItemChange(index) {
      this.customIndex = index;
    },
    onsubmit() {
      if (this.info.phone == "") {
        this.$toast.warning("请填写电话号码");
        return false;
      }
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 1,
          name: this.info.user,
          mobile: this.info.phone,
          category_id: "",
          describe: "",
          area: ""
        })
        .then(res => {
          console.log(res);
          if (res.errcode == 200) {
            this.$toast.success("提交成功");
          } else {
            this.$toast.error(res.msg);
          }
        });
    }
  },
  components: {
    Header: header,
    Footer: footer
    // VuePicker,
  }
};
</script>
<style>
.casedetail .as {
  width: 280px;
}
.casedetail .ab {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  color: #ffa405;
}
</style>
