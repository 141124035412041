<template>
  <div class="yicase">
    <Header />
    <div class="xia">
      <div>
        <wd-img :src="imgUrl + 'case/banner.png'" fit="cover" />
      </div>
      <!-- nav -->
      <div class="bgwhite ptb10">
        <wd-drop-menu>
          <wd-drop-menu-item
            v-model="value1"
            :options="option1"
            title="项目类型"
            @change="chosenav1"
          />
          <wd-drop-menu-item
            v-model="value2"
            :options="option2"
            title="面积"
            @change="chosenav2"
          />
          <!-- <wd-drop-menu-item
            v-model="value3"
            :options="option3"
            title="价格范围"
            @change="chosenav3"
          /> -->
          <wd-drop-menu-item
            v-model="value4"
            :options="option4"
            title="施工店铺"
            @change="chosenav4"
          />
        </wd-drop-menu>
      </div>
      <!-- list -->
      <div class="flex_center flex_items">
        <div v-if="list.length != 0" class="flex_column flex_items">
          <div
            v-for="(item, index) in list"
            :key="index"
            class=""
            @click="casedetail(item.id)"
          >
            <wd-img
              :src="tuhose + item.url"
              fit="cover"
              style="width:375px;height:280px"
              mode="aspectFill"
            />
            <div class="flex_column p20">
              <div class="flex_between">
                <span class="bold f22">{{ item.title }}</span>
                <div class="flex_start flex_items">
                  <wd-img
                    :src="imgUrl + 'case/chakan.png'"
                    fit="cover"
                    class="mr10"
                  />
                  <span class="f20" style="color: #8d8d8d">{{
                    item.views
                  }}</span>
                </div>
              </div>
              <p class="f18 mt10 f-left" style="color: #8d8d8d">
                {{ item.name }}&nbsp;|&nbsp;{{ item.area }}m²
              </p>
            </div>
          </div>
        </div>
        <div v-else class="noDetail">--暂无数据--</div>
      </div>
    </div>
    <div class="bgwhite pt40">
      <Footer />
    </div>
  </div>
</template>
<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      tuhose: this.util.tuhose,
      wens: [],
      info: {
        user: "",
        phone: ""
      },
      value1: 0,
      value2: 0,
      value3: 0,
      value4: 0,
      option1: [],
      option2: [],
      option3: [],
      option4: [],
      list: []
    };
  },
  created() {
    this.$store.commit("setpage", "益鸟案例");
    this.getData();
  },
  methods: {
    getData() {
      this.util
        .get(this.HOST + "/api/example/", {
          service_category_id: this.value1,
          price_category_area: this.value2,
          price_category_id: this.value3,
          store_id: this.value4,
          keywords: ""
        })
        .then(res => {
          console.log(res);
          if (res.errcode == 200) {
            res.data.stores.unshift({
              name: "不限",
              store_id: 0
            });
            res.data.service_category_arr.unshift({
              name: "不限",
              category_id: 0
            });
            res.data.price_category_area.unshift({
              name: "不限",
              category_id: 0
            });
            res.data.price_category_arr.unshift({
              name: "不限",
              category_id: 0
            });
            this.option1 = [];
            this.option2 = [];
            this.option3 = [];
            this.option4 = [];
            for (let i = 0; i < res.data.service_category_arr.length; i++) {
              this.option1.push({
                label: res.data.service_category_arr[i].name,
                value: res.data.service_category_arr[i].category_id
              });
            }
            for (let i = 0; i < res.data.price_category_area.length; i++) {
              this.option2.push({
                label: res.data.price_category_area[i].name,
                value: res.data.price_category_area[i].category_id
              });
            }
            for (let i = 0; i < res.data.price_category_arr.length; i++) {
              this.option3.push({
                label: res.data.price_category_arr[i].name,
                value: res.data.price_category_arr[i].category_id
              });
            }
            for (let i = 0; i < res.data.stores.length; i++) {
              this.option4.push({
                label: res.data.stores[i].name,
                value: res.data.stores[i].store_id
              });
            }
            this.list = res.data.data;
          }
        });
    },
    chosenav1(value) {
      this.value1 = value;
      this.getData();
    },
    chosenav2(value) {
      this.value2 = value;
      this.getData();
    },
    chosenav3(value) {
      this.value3 = value;
      this.getData();
    },
    chosenav4(value) {
      this.value4 = value;
      this.getData();
    },
    casedetail(idx) {
      this.$router.push({
        path: "/casedetail",
        query: {
          id: idx
        }
      });
    }
  },
  components: {
    Header: header,
    Footer: footer
    // VuePicker,
  }
};
</script>
<style>
.yicase .wd-drop-menu__item-title-text,
.wd-grid,
.wd-grid-item {
  margin-right: 13px !important;
}
.yicase .wd-drop-menu__list {
  font-size: 14px !important;
}
.yicase .wd-drop-item__option {
  font-size: 14px !important;
  padding: 3px 0 3px 25px;
}
</style>
