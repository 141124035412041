<template>
  <div class="weixiu">
    <Header />
    <div class="">
      <div>
        <wd-img :src="imgUrl + 'wei/banner.png'" fit="cover" />
      </div>
      <div class="flex_column flex_items">
        <wd-img :src="imgUrl + 'wei/wenti.png'" fit="cover" />
        <wd-img :src="imgUrl + 'wei/fuwuyoushi.png'" fit="cover" />
        <wd-img :src="imgUrl + 'wei/shequdianpu.png'" fit="cover" />
        <wd-img :src="imgUrl + 'wei/shigonggongren.png'" fit="cover" />
        <wd-img :src="imgUrl + 'wei/gongren.png'" fit="cover" />
      </div>
      <!-- 案例展示 -->
      <div class="bgwhite plr20 pt20 pb40">
        <div class="flex_items flex_column mt20">
          <p class="bold f32">展示施工案例，工地就在您身边</p>
        </div>
        <div class="flex_warp flex_items mt20 flex_box">
          <div
            class="  mt20 mm "
            v-for="(item, index) in shigongs"
            :key="index"
            style="width: 48%"
          >
            <wd-img :src="item.url" fit="cover" />
            <p class="bold f20 mt15 f-center">{{ item.t1 }}·{{ item.t2 }}</p>
          </div>
        </div>
        <wd-button
          type="info"
          block
          class="mt30 mu"
          @click="morecase"
          style="
            height: 40px;
            font-size: 16px !important;
            color: #666666;
            background: white;
          "
          >查看更多</wd-button
        >
      </div>
      <wd-img :src="imgUrl + 'fang/koubei.png'" fit="cover" />
    </div>
    <div class="bgwhite pt40">
      <Footer />
    </div>
  </div>
</template>
<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      wens: [],
      info: {
        user: "",
        phone: ""
      },
      shigongs: [
        {
          url: this.util.imgUrl + "wei/anlie1.png",
          t1: "保利玫瑰花语",
          t2: "家电安装"
        },
        {
          url: this.util.imgUrl + "wei/anlie2.png",
          t1: "电建洺悦府",
          t2: "电路维修"
        },
        {
          url: this.util.imgUrl + "wei/anlie3.png",
          t1: "新华小区",
          t2: "水龙头换新"
        },
        {
          url: this.util.imgUrl + "wei/anlie4.png",
          t1: "汇融悉尼湾",
          t2: "洁具维修"
        },
        {
          url: this.util.imgUrl + "wei/anlie5.png",
          t1: "柳岸银州",
          t2: "灯具安装"
        },
        {
          url: this.util.imgUrl + "wei/anlie6.png",
          t1: "山水时光",
          t2: "五金安装"
        }
      ]
    };
  },
  created() {
    this.$store.commit("setpage", "产品中心");
  },
  methods: {
    morecase() {
      this.$router.push({
        path: "/yicase",
        query: {}
      });
    },
    onsubmit() {
      if (this.info.phone == "") {
        this.$toast.warning("请填写电话号码");
        return false;
      }
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 1,
          name: this.info.user,
          mobile: this.info.phone,
          category_id: "",
          describe: "",
          area: ""
        })
        .then(res => {
          console.log(res);
          if (res.errcode == 200) {
            this.$toast.success("提交成功");
          } else {
            this.$toast.error(res.msg);
          }
        });
    }
  },
  components: {
    Header: header,
    Footer: footer
  }
};
</script>

<style>
.fang .t6 {
  background: url("https://imgaes.yiniaoweb.com/gw/mobile/fang/beijing.png")
    no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;
  width: 100%;
}
.weixiu .mm:nth-child(2),
.weixiu .mm:nth-child(4),
.weixiu .mm:nth-child(6) {
  margin-right: 0 !important;
}
.mu .wd-button__txt {
  font-size: 26px !important;
}
.flex_box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
