<template>
  <div class="about">
    <Header />
    <wd-img :src="imgUrl + 'about/banner.png'" fit="cover" />
    <div class="flex_column flex_items">
      <wd-img :src="imgUrl + 'about/gongsijieshao.png'" fit="cover" />
      <!-- <wd-img :src="imgUrl + 'about/licheng345.jpg'" fit="cover" /> -->
      <wd-img :src="imgUrl + 'about/youshi1.png'" fit="cover" />
      <wd-img :src="imgUrl + 'about/youshi2.png'" fit="cover" />
    </div>
    <div class="bgwhite">
      <!-- <div class="flex_items flex_column">
        <p class="bold f32 mt30">招纳贤士</p>
      </div>
      <div class="flex_column p20 f22" style="color: #8d8d8d">
        <div class="f-left flex_start flex_items">
          <span class="f40 bold mr10">◆</span>
          <span>我们是一群什么样的人？忽然让我们描述自己，有点不知所措。</span>
        </div>
        <div class="f-left flex_start flex_items mt10">
          <span class="f40 bold mr10">◆</span>
          <span>我想，我们就是这样一群人~</span>
        </div>
        <div class="f-left flex_start flex_items mt10">
          <span class="f40 bold mr10">◆</span>
          <span
            >真诚是我们最大的特质，无论对客户，还是对自己的伙伴~
            我们坦诚开放，简单直接~</span
          >
        </div>
        <div class="f-left flex_start flex_items mt10">
          <span class="f40 bold mr10">◆</span>
          <span
            >我们视“执行力为命”，因为我们认为“一万个梦想，不如一个行动”坚持，是我们认为最大的力量，所以我们一直在路上~</span
          >
        </div>
        <div class="f-left flex_start flex_items mt10">
          <span class="f40 bold mr10">◆</span>
          <span
            >当然，我们有时候处女上身细节控，有时候有点逗比有点疯；爱喝奶茶，爱撸猫，爱户外，爱健身，热爱居家生活的小零碎儿~</span
          >
        </div>
        <div class="f-left flex_start flex_items mt10">
          <span class="f40 bold mr10">◆</span>
          <span
            >我们期待那个未来的你，那个即将加入的你，我们希望你乐观积极，爱拼搏爱努力，更重要的是，我们相信你~</span
          >
        </div>
        <div class="f-left flex_start flex_items mt10">
          <span class="f40 bold mr10">◆</span>
          <span>我想，我们就是这样一群人~</span>
        </div>
      </div>
      <div class="flex_column p20 f22" style="color: #8d8d8d">
        <p class="bold f30 mt28 f-left" style="color: #333">职位信息:</p>
        <div class="f-left flex_start flex_items mt20">
          <span class="f40 bold mr10">◆</span>
          <span
            >各个岗位的店长工程师（也是我们转化圣斗士）负责为我们的业主大大上门测量、诊断、设计，出具方案和报价~</span
          >
        </div>
        <div class="f-left flex_start flex_items">
          <span class="f40 bold mr10">◆</span>
          <span
            >维修工程师（也是妙手老）、乳胶漆、水电、瓷砖铺贴、各路房屋维修翻新的超人我们都要~</span
          >
        </div>
      </div> -->
      <!-- list -->
      <!-- <div class="plr20 pb30">
        <div
          v-for="(item, index) in lists"
          :key="index"
          class="p20 flex_column mt20"
          style="background: #f5f5f5"
          @click="lala(item)"
        > -->
      <!-- <div class="bold f26 flex_between flex_items">
            <span class="f-left" style="width:180px">{{ item.title }}</span>
            <span class="f-left" style="color: red">{{ item.salary }}</span>
          </div>
          <div class="flex_between flex_items f22 mt20 pb20 bbe9s">
            <span class="f-left mr10 zhaodi" style="width: 160px">{{
              item.place
            }}</span>
            <div class="flex_start">
              <span class="mr10">招聘{{ item.count }}人</span>
              <span class="">{{ item.education }}</span>
            </div>
          </div> -->
      <!-- <div class="flex_column f22 f-left pb30 bbe9s">
            <p class="mt20">
              岗位职责:&nbsp; <span v-html="item.duty_content"></span>
            </p>
            <p class="mt20">
              任职资格:&nbsp;<span v-html="item.demand_content"></span>
            </p>
          </div> -->
      <!-- <div class="flex_column f22 f-left pb30 bbe9s">
            <p class="mt20">简历邮递邮箱:&nbsp;{{ item.email }}</p>
            <p class="mt20">联系电话:&nbsp; {{ item.phone }}</p>
          </div>
        </div> -->
      <!-- </div> -->
      <!-- 预约 -->
      <div
        class="plr20 flex_column flex_items pb30 pos pb20"
        style="height: 650px"
      >
        <wd-img
          :src="imgUrl + 'about/beijing.png'"
          fit="cover"
          class="abs"
          style="width: 100%; left: 0; top: 0; height: 650px"
        />
        <div class="abs" style="width: 84%">
          <div class="flex_items flex_column mt20">
            <p class="bold f30 mt30" style="color: white">联系我们</p>
          </div>
          <div class="flex_column mt40" style="width: 100%">
            <div class="flex_start pos" style="width: 100%">
              <input
                type="text"
                placeholder="姓名"
                v-model="info.user"
                class="f24 p5"
                @click="show = true"
                style="
                  height: 30px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  width: 100%;
                  padding-left:10px;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 电话 -->
            <div class="flex_start pos mt20" style="width: 100%">
              <input
                type="text"
                placeholder="联系电话"
                v-model="info.phone"
                class="f24 p5"
                style="
                  height: 30px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  width: 100%;
                  padding-left:10px;
                "
              />
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <!-- 房屋问题 -->
            <div
              class="flex_start pos mt20"
              style="width: 100%; background: white"
            >
              <div
                type="text"
                class="f24 p5 f-left"
                @click="pickerVisible = true"
                style="
                  height: 30px;
                  border: 1px solid #d5d5d5;
                  outline: none;
                  width: 100%;
                  line-height: 35px;
                  color: #8d8d8d;
                  padding-left:10px;
                "
              >
                {{ result }}
              </div>
              <span style="color: red; right: 10px; top: 15px" class="f22 abs"
                >*</span
              >
            </div>
            <wd-button
              @click="onsubmit"
              block
              size="large"
              class="mt30"
              style="
                height: 35px;
                line-height:35px;
                background: #d7ba7e;
                border-radius: 0;
                color: #666;
                font-weight: bold;
              "
              >立即提交</wd-button
            >
          </div>
          <div class="mt20 f18 mt20" style="color: #d7ba7e">
            <div class="flex_start flex_items">
              <wd-img
                :src="imgUrl + 'about/lianxidianhua.png'"
                fit="cover"
                class="mr10"
                width="26"
                height="26"
              />
              <span>联系电话: 4000-987-009</span>
            </div>
            <div class="flex_start flex_items mt10">
              <wd-img
                :src="imgUrl + 'about/youxiang.png'"
                fit="cover"
                class="mr10"
                width="26"
                height="26"
              />
              <span>邮箱地址: xuzhihui@yiniaoo2o.com</span>
            </div>
            <div class="flex_start flex_items mt10">
              <!-- <wd-img
                :src="imgUrl + 'about/dizhi.png'"
                fit="cover"
                class="mr10"
                width="36"
                height="36"
              /> -->
              <!-- <span class="f-left">公司地址: 成都市金牛区万通金融广场西塔3137-3140</span> -->
              <!-- <span class="f-left">公司地址: 上海【闵行店】闵行区水清路1050号</span> -->
            </div>
            <!-- <wd-img class="mt30" :src="imgUrl + 'about/ditu.png'" fit="cover" /> -->
          </div>
        </div>
      </div>
      <Footer />
    </div>
    <div v-if="pickData.length != 0">
      <VuePicker
        :data="pickData"
        :showToolbar="true"
        @confirm="confirm"
        :visible.sync="pickerVisible"
      />
    </div>
  </div>
</template>
<script>
import header from "@/components/header.vue";
import footer from "@/components/footer.vue";
import VuePicker from "vue-pickers";
let tdata = [];
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      lists: [],
      info: {
        user: "",
        phone: ""
      },
      // picker
      pickerVisible: false,
      pickData: [tdata],
      result: "选择房屋问题"
    };
  },
  created() {
    this.$store.commit("setpage", "关于我们");
    this.getData();
  },
  methods: {
    getData() {
      this.util.get(this.HOST + "/api/recruit", {}).then(res => {
        console.log(res);
        if (res.errcode == 200) {
          this.lists = res.data.lists;
          this.getlei();
        }
      });
    },
    getlei() {
      this.util.get(this.HOST + "/api/service_category/", {}).then(res => {
        console.log(res);
        if (res.errcode == 200) {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].value = res.data[i].category_id;
            res.data[i].label = res.data[i].name;
            if (tdata.length <= 3) {
              tdata.push({
                value: res.data[i].category_id,
                label: res.data[i].name
              });
            }
          }
          this.loading = false;
        }
      });
    },
    lala(item) {
      localStorage.setItem("zhi", JSON.stringify(item));
      this.$router.push({
        path: "/rezhao",
        query: {}
      });
    },
    confirm(res) {
      console.log(res);
      this.result = res[0].label;
      this.info.wen = res[0].value;
    },
    onsubmit() {
      if (this.info.phone == "") {
        this.$toast.warning("请填写电话号码");
        return false;
      }
      this.util
        .getinfo(this.HOST + "/api/indexForm", {
          source_type: 1,
          name: this.info.user,
          mobile: this.info.phone,
          category_id: "",
          describe: "",
          area: ""
        })
        .then(res => {
          console.log(res);
          if (res.errcode == 200) {
            this.$toast.success("提交成功");
          } else {
            this.$toast.error(res.msg);
          }
        });
    }
  },
  components: {
    Header: header,
    Footer: footer,
    VuePicker
  }
};
</script>
<style>
/* .heihei {
  background: url("https://imgaes.yiniaoweb.com/gw/mobile/about/beijing.png")
    no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  width: 100%;
} */
.zhaodi {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  width: 100%;
  -webkit-box-orient: vertical;
}
</style>
